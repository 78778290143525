import classNames from 'classnames';
import { SkeletonLine } from '../atoms/LoadingSkeleton';
import QuestionMarkTooltip from '../QuestionMarkTooltip';

const Card = ({
  icon,
  children,
  title,
  loading,
  tooltip,
  footer,
  skeletonLineWidth,
  className,
}: {
  children: React.ReactNode;
  icon?: JSX.Element;
  title?: string;
  loading?: boolean;
  tooltip?: string;
  footer?: React.ReactNode;
  skeletonLineWidth?: number;
  className?: string;
}) => (
  <div
    className={classNames(
      'flex grow flex-col justify-between gap-y-2 rounded-md border border-cf-gray-3-5 bg-cf-gray-3 p-5',
      className,
    )}
  >
    <div className="flex items-center space-x-1">
      {/* dummy div to prevent the icon from scaling when it gets squished */}
      <div>{icon}</div>
      <span className="font-aeonikMedium text-14 text-cf-light-2">{title}</span>
      <QuestionMarkTooltip content={tooltip} />
    </div>
    <div className="font-aeonikMedium text-20 text-cf-light-4">
      {loading ? <SkeletonLine width={skeletonLineWidth} /> : children}
    </div>
    <div className="text-12 text-cf-light-2">{footer}</div>
  </div>
);

export default Card;
