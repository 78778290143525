import { useEffect, useRef, useState } from 'react';
import className from 'classnames';
import { motion } from 'framer-motion';

export type Tab<T> = {
  label: string;
  value?: T;
  activeClasses?: {
    btn?: string;
    pill?: string;
  };
};

export const TabSelect = <T,>({
  tabs,
  selectedTab,
  onSelectionChange,
  btnClasses,
  pillWidth = 140,
  pillHeight = 34,
  containerMargin = 5,
}: {
  tabs: readonly Tab<T>[];
  selectedTab: Tab<T>;
  onSelectionChange: (option: Tab<T>) => void;
  btnClasses?: string;
  pillWidth?: number;
  pillHeight?: number;
  containerMargin?: number;
}) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const [selectedBtn, setSelectedBtn] = useState<HTMLButtonElement | null>();

  const containerBorderSize = 1;
  const pillClasses = selectedTab.activeClasses?.pill;

  const [animateX, setAnimateX] = useState(0);

  useEffect(() => {
    if (selectedBtn && parentRef.current) {
      const childPos = selectedBtn.getBoundingClientRect();
      const parentPos = parentRef.current.getBoundingClientRect();
      // `getBoundingClientRect` includes the padding + border when calculating the element position
      setAnimateX(childPos.left - parentPos.left - containerMargin - containerBorderSize);
    }
  }, [selectedBtn]);
  return (
    <div
      className="relative flex rounded-md border-cf-gray-4 bg-cf-gray-3-5"
      ref={parentRef}
      style={{
        padding: `${containerMargin}px`,
        borderWidth: `${containerBorderSize}px`,
      }}
    >
      <motion.div
        initial={{ x: animateX }}
        animate={{ x: animateX }}
        transition={{ duration: 0.15 }}
        className={className(
          'pointer-events-none absolute z-0 rounded-md border border-cf-gray-5 bg-cf-gray-4',
          pillClasses,
        )}
        style={{
          width: `${pillWidth}px`,
          height: `${pillHeight}px`,
        }}
      />
      {tabs.map((tab) => {
        const isSelected = tab.label === selectedTab.label;
        const activeBtnClasses = selectedTab.activeClasses?.btn;
        return (
          <button
            key={tab.label}
            type="button"
            className={className(
              `z-10 rounded-[4px] text-center font-aeonikMedium transition ease-in`,
              isSelected && !activeBtnClasses && 'text-white',
              (isSelected && activeBtnClasses) || 'text-cf-light-2 hover:text-white',
              btnClasses,
            )}
            onClick={() => onSelectionChange(tab)}
            style={{
              width: `${pillWidth}px`,
              height: `${pillHeight}px`,
            }}
            ref={(element) => {
              if (isSelected) setSelectedBtn(element);
            }}
          >
            {tab.label}
          </button>
        );
      })}
    </div>
  );
};
