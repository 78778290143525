import { Card } from '@/shared/components';
import { useBoostPoolInfo } from '@/shared/hooks';
import { type BoostPoolInfoProps } from '@/shared/hooks/useBoostPoolInfo';
import { ChartIcon, DropletIcon, MoneyBagIcon, PercentageIcon } from '@/shared/icons/large';
import { chainflipAssetMap, formatUsdValue } from '@/shared/utils';
import { TrendingDownIcon, TrendingUpIcon } from '../../icons/small';

export const BoostPoolCards = ({
  asset,
  feeTier,
  account,
  className,
}: BoostPoolInfoProps & { className?: string }) => {
  const { liquidity, liquidityUsd, volume, volumeUsd, fees, feesUsd, apy } = useBoostPoolInfo({
    feeTier,
    asset,
    account,
  });

  const skeletonLineWidth = 100;
  const assetSymbol = chainflipAssetMap[asset].symbol;

  const apy24hrDelta =
    typeof apy?.oneDayAverage === 'number' &&
    typeof apy?.threeDayAverage === 'number' &&
    apy.oneDayAverage - apy.threeDayAverage;

  const apyDeltaNegative = typeof apy24hrDelta === 'number' && apy24hrDelta < 0;

  return (
    <>
      <Card
        skeletonLineWidth={skeletonLineWidth}
        loading={false}
        icon={<PercentageIcon className="text-cf-light-2" />}
        title="APY"
        tooltip=""
        className={className}
        footer={
          <div className="flex items-center gap-x-1">
            <span className="text-cf-light-2">Last 24H</span>
            {apyDeltaNegative ? (
              <TrendingDownIcon className="text-cf-red-2" />
            ) : (
              <TrendingUpIcon className="text-cf-green-3" />
            )}
            <span className={apyDeltaNegative ? 'text-cf-red-2' : 'text-cf-green-3'}>
              {apy24hrDelta && apy24hrDelta.toFixed(4)}%
            </span>
          </div>
        }
      >
        <div className="-my-0.5 text-[32px]">{apy?.threeDayAverage?.toFixed(4)}</div>
      </Card>
      <Card
        skeletonLineWidth={skeletonLineWidth}
        loading={liquidity === null}
        icon={<DropletIcon className="text-cf-light-2" />}
        title="Boost Liquidity"
        footer="Current"
        className={className}
      >
        <div className="flex flex-wrap items-center gap-x-1.5">
          <span className="text-20 text-white">
            {liquidity?.toPreciseFormattedString()} {assetSymbol}
          </span>
          <span className="font-aeonikRegular text-12 text-cf-light-2">
            {formatUsdValue(liquidityUsd)}
          </span>
        </div>
      </Card>
      <Card
        skeletonLineWidth={skeletonLineWidth}
        loading={fees === null}
        icon={<MoneyBagIcon className="w-[22px] text-cf-light-2" />}
        title="Earned Fees"
        footer="All time"
        className={className}
      >
        <div className="flex flex-wrap items-center gap-x-1.5">
          <span className="text-20 text-white">
            {fees?.toPreciseFormattedString()} {assetSymbol}
          </span>
          <span className="text-12 text-cf-light-2">{formatUsdValue(feesUsd)}</span>
        </div>
      </Card>
      {!account && (
        <Card
          skeletonLineWidth={skeletonLineWidth}
          loading={volume === null}
          icon={<ChartIcon className="text-cf-light-2" />}
          title="Volume"
          footer="All time"
          className={className}
        >
          <div className="flex flex-wrap items-center gap-x-1.5">
            <span className="text-20 text-white">
              {volume?.toPreciseFormattedString()} {assetSymbol}
            </span>
            <span className="text-12 text-cf-light-2">{formatUsdValue(volumeUsd)}</span>
          </div>
        </Card>
      )}
    </>
  );
};
