import { useMemo } from 'react';
import { type ApolloError, type QueryHookOptions, useQuery } from '@apollo/client';
import { flip$ } from '@/shared/assets/tokens';
import { gql } from '@/shared/graphql/generated';
import useTokenPrice from '@/shared/hooks/useTokenPrice';
import { BLOCK_TIME_IN_SECONDS } from '../constants';
import {
  type GetLatestAuctionQuery,
  type GetLatestAuctionQueryVariables,
} from '../graphql/generated/graphql';
import { type TokenAmount } from '../utils';
import FlipAmount from '../utils/FlipAmount';

export const getLatestAuctionQuery = gql(/* GraphQL */ `
  query GetLatestAuction {
    auction: auctionById(id: 1) {
      minActiveBid
      startBlockNumber
      endBlockNumber
      currentHeight
      projectedLockup
      redemptionPeriodAsPercentage
      targetSetSize
      authorityRotationEnabled
    }
  }
`);

export type AuctionInfo = {
  percentageElapsed: number;
  epochLengthBlocks: number;
  startsAt: Date;
  endsAt: Date;
  hasEnded: boolean;
  minActiveBid: TokenAmount | undefined;
  projectedLockup: TokenAmount | undefined;
  minActiveBidUSDC: string | undefined;
  projectedLockupUSDC: string | undefined;
  isRedemptionPeriod: boolean;
  redemptionPeriodAsPercentage: number;
  targetSetSize: number;
  authorityRotationEnabled: boolean;
};

type AuctionHook = {
  auction: AuctionInfo | null;
  loading: boolean;
  error?: ApolloError;
  initialized: boolean;
};

export default function useAuction(
  options?: QueryHookOptions<GetLatestAuctionQuery, GetLatestAuctionQueryVariables>,
): AuctionHook {
  const { price: flipUsdcPrice = 0 } = useTokenPrice(flip$);
  const result = useQuery(getLatestAuctionQuery, options);

  return useMemo(() => {
    let auctionInfo = null;

    const { data, loading, error, previousData } = result;

    if (data?.auction) {
      const {
        endBlockNumber,
        currentHeight,
        startBlockNumber,
        redemptionPeriodAsPercentage,
        targetSetSize,
        authorityRotationEnabled,
        ...auction
      } = data.auction;

      const auctionStartBlock = Math.round((startBlockNumber + endBlockNumber) / 2);
      const blocksToStart = auctionStartBlock - currentHeight;

      const blocksRemaining = endBlockNumber - currentHeight;
      const auctionHasEnded = blocksRemaining <= 0;

      const epochLengthBlocks = endBlockNumber - startBlockNumber;
      const percentageElapsed = auctionHasEnded
        ? 100
        : (1 - blocksRemaining / epochLengthBlocks) * 100;
      const isRedemptionPeriod = percentageElapsed < redemptionPeriodAsPercentage;

      const minActiveBid =
        auction.minActiveBid == null ? undefined : new FlipAmount(auction.minActiveBid);

      const projectedLockup =
        auction.projectedLockup == null ? undefined : new FlipAmount(auction.projectedLockup);

      const minActiveBidUSDC = minActiveBid?.toBigNumber().multipliedBy(flipUsdcPrice);

      const projectedLockupUSDC = projectedLockup?.toBigNumber().multipliedBy(flipUsdcPrice);

      auctionInfo = {
        percentageElapsed,
        epochLengthBlocks,
        startsAt: new Date(blocksToStart * (BLOCK_TIME_IN_SECONDS * 1000) + Date.now()),
        endsAt: new Date(blocksRemaining * (BLOCK_TIME_IN_SECONDS * 1000) + Date.now()),
        hasEnded: auctionHasEnded,
        minActiveBid,
        projectedLockup,
        minActiveBidUSDC: minActiveBidUSDC && minActiveBidUSDC.toString(),
        projectedLockupUSDC: projectedLockupUSDC?.toString(),
        isRedemptionPeriod,
        redemptionPeriodAsPercentage,
        targetSetSize,
        authorityRotationEnabled,
      };
    }

    return {
      auction: auctionInfo,
      loading,
      error,
      initialized: Boolean(auctionInfo || previousData),
    };
  }, [result, flipUsdcPrice]);
}
